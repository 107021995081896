export function getDropDownTemplate(id, name, icon) {
  return `
     <div class="country d-flex align-items-center">
     ${
       icon
         ? `<div class="country-icon">
     <img src="${icon}" alt="${id}" />
   </div>`
         : ""
     }
        <span class="country-name">${
          name?.length > 35 ? name.slice(0, 35) + "..." : name
        } </span>
     </div>
  `;
}
