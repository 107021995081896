import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2c67d94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EsimDirectoryDialogsController = _resolveComponent("EsimDirectoryDialogsController")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_AppToolbarTable = _resolveComponent("AppToolbarTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EsimDirectoryDialogsController, {
      id: _ctx.id,
      "edit-data": _ctx.editData,
      "data-types": _ctx.dataTypes,
      "esim-servers-free": _ctx.esimServersFree,
      "esim-servers-all": _ctx.esimServersAll,
      "modal-title": _ctx.modalTitle,
      modals: _ctx.modals,
      type: _ctx.directoryType,
      uploader: _ctx.uploadImageHandler,
      onSubmit: _ctx.onSubmit,
      onCleanData: _ctx.cleanData
    }, null, 8, ["id", "edit-data", "data-types", "esim-servers-free", "esim-servers-all", "modal-title", "modals", "type", "uploader", "onSubmit", "onCleanData"]),
    _createVNode(_component_AppTableController, {
      "show-table": true,
      "empty-button-text": "Add new",
      "empty-key": "Locations",
      "use-loader": false,
      "empty-icon": "locations"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PageMainHeading, { title: "Esim Directory" })
        ]),
        _createVNode(_component_AppToolbarTable, {
          "pagination-capture-key": _ctx.pageTitle,
          "table-title": _ctx.pageTitle,
          "table-head-table": _ctx.tableHead,
          "table-body": _ctx.rows || [],
          "total-rows": _ctx.totalDirectories,
          "table-head-tabs": _ctx.tabs,
          "show-head-toolbar": false,
          "space-between": false,
          "with-footer": false,
          onChangeTab: _ctx.changeTabHandler,
          onSort: _ctx.changeSorting,
          onEditItem: _cache[0] || (_cache[0] = (id)=> _ctx.startEditing(id)),
          onOpenForm: _ctx.toogleModal,
          onRemoveItem: _ctx.removeItemOpener
        }, null, 8, ["pagination-capture-key", "table-title", "table-head-table", "table-body", "total-rows", "table-head-tabs", "onChangeTab", "onSort", "onOpenForm", "onRemoveItem"])
      ]),
      _: 1
    })
  ], 64))
}