import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTableBodyCell = _resolveComponent("AppTableBodyCell")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_AppTableLayout = _resolveComponent("AppTableLayout")!

  return (_openBlock(), _createBlock(_component_AppTableLayout, _mergeProps({
    "table-head": _ctx.tableHead,
    "pagination-capture-key": _ctx.paginationCaptureKey,
    "total-rows": _ctx.totalRows,
    "per-page": _ctx.perPage,
    "current-page": _ctx.currentPage
  }, _ctx.$attrs.class, {
    class: { withMoreMenu: _ctx.withMoreMenu },
    "space-between": _ctx.spaceBetween,
    "with-footer": _ctx.withFooter,
    onSort: _cache[0] || (_cache[0] = 
      (...arg: any[]) => {
        _ctx.$emit('sort', ...arg);
      }
    ),
    onChangeViewConfiguration: _cache[1] || (_cache[1] = 
      (conf) => {
        _ctx.$emit('changeViewConfiguration', conf);
      }
    )
  }), {
    "table-body": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedRows, ({
          id,
          actions,
          withAction,
          status,
          cells,
          bgStatus = '',
          onClick = () => {}
        }, idx) => {
        return (_openBlock(), _createBlock(_component_TableRow, {
          key: idx,
          status: status,
          "more-menu": actions,
          "space-between": _ctx.spaceBetween,
          class: _normalizeClass({
          'with-footer': !_ctx.withFooter,
          'hover-scale': withAction,
          [`status-${bgStatus}`]: true
        }),
          onClick: onClick
        }, {
          content: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cells, ({ componentName, componentContent, cellProps, params }, idx) => {
              return (_openBlock(), _createBlock(_component_AppTableBodyCell, _mergeProps({
                id: id,
                key: idx,
                "component-info": {
              name: componentName,
              content: componentContent
            },
                "hide-button": _ctx.hideButton,
                "right-align": params.rightAlign
              }, { ...cellProps }, {
                onEditItem: _ctx.editItem,
                onDeleteItem: _ctx.deleteItem,
                onSelectData: _ctx.selectData
              }), null, 16, ["id", "component-info", "hide-button", "right-align", "onEditItem", "onDeleteItem", "onSelectData"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["status", "more-menu", "space-between", "class", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["table-head", "pagination-capture-key", "total-rows", "per-page", "current-page", "class", "space-between", "with-footer"]))
}