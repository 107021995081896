import { esimDirectoryActions } from "./modules/esimDirectoryActions";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { uploadEsimDirectoryImages } from "./modules/uploadEsimDirectoryImages";
import { esimDirectoriesTabs } from "./instanse/esimDirectoriesTabs";
import { ITab } from "@/store/interfaces/common";
import { generateEsimDirectoryTableHead } from "./modules/generateEsimDirectoryTableHead";
import { sortOrders } from "./../../../enums/main/sortOrders";
import { esimDirectoriesList } from "./modules/esimDirectoriesList";
import { IApiResponse } from "./../../../api/interfaces/IApi";
import { useBaseAppLayout } from "@/hooks/layout/useBaseAppLayout";
import { generateEsimDirectoriesRows } from "./modules/generateEsimDirectoriesRows";
import { getEsimDirectorySortConfig } from "./modules/getEsimDirectorySortConfig";
import { computed, ComputedRef, Ref, ref, watch } from "vue";
import { useStore } from "vuex";
import {
  esimDirectoriesAddLinks,
  esimDirectoriesDeleteLinks,
  esimDirectoriesEditLinks,
  esimDirectoriesTitles,
  esimDirectoriesTypes,
  TEsimDirectoryTypes
} from "./types/esimDirectories.types";
import { useTableWithOutViewConfiguration } from "@/hooks/tables/useTable";
import { fetchEsimDirectories } from "./modules/fetchEsimDirectories";

export const useEsimDirectories = (directoryType: Ref<TEsimDirectoryTypes>) => {
  const store = useStore();

  const esimDirectoriesModel = computed(() => {
    return store.getters.esimDirectoriesList(directoryType.value);
  });

  const selectedTab: Ref<ITab> = ref({
    ...esimDirectoriesTabs()[0]
  });

  watch(
    selectedTab,
    async () => {
      if (!esimDirectoriesModel.value.length)
        await fetchData(directoryType.value);
    },
    {
      immediate: true,
      deep: true
    }
  );
  const currentSortKeyLocationName = computed(() => {
    if (!directoryType) {
      return "";
    }

    return esimDirectoriesTypes[directoryType.value];
  });

  const initialSortOptions = getEsimDirectorySortConfig(
    currentSortKeyLocationName
  );

  const generateTableRows = generateEsimDirectoriesRows();

  const {
    filter,
    searchQuery,
    selectedFilterOption,
    onFilterSelect,
    searchHandler
  } = useBaseAppLayout({
    initialSearchQuery: "",
    filterOptions: []
  });

  const localeCurrentSort = ref({
    keyName: initialSortOptions.byTitle.keyName,
    order: initialSortOptions.byTitle.order
  });

  const {
    viewConfiguration,
    rows,
    currentSort,
    sortedModel,
    changeSorting
  } = useTableWithOutViewConfiguration({
    initialSortOptions: initialSortOptions,
    model: esimDirectoriesModel,
    generateRows: generateTableRows
  });

  async function fetchData(type: TEsimDirectoryTypes): Promise<IApiResponse> {
    return await fetchEsimDirectories({
      directoryType: type,
      store
    });
  }

  watch(
    currentSort,
    sort => {
      const { keyName = "", order = sortOrders.turnOff } =
        Object.values(sort).find(({ order }) => order !== sortOrders.turnOff) ||
        {};

      localeCurrentSort.value = { keyName, order };
    },
    { immediate: true, deep: true }
  );

  const pageTitle: ComputedRef<string> = computed(() => {
    if (!directoryType.value) {
      return "Validity Period";
    }
    return esimDirectoriesTitles[directoryType.value];
  });
  const withStatus = computed(() => {
    return esimDirectoriesModel.value.some(
      (el: any) => typeof el?.active === "boolean"
    );
  });

  const tableHead = computed(() =>
    generateEsimDirectoryTableHead(withStatus.value, currentSort)
  );

  const tabs = esimDirectoriesTabs();

  const uploadImageHandler = (data: { type: string; file: File }) => {
    return uploadEsimDirectoryImages(store, data);
  };

  const submitHandler = async (
    data: IEsimDirectoryDefault,
    id: number | null
  ) => {
    let link = "";
    if (!!id && data) {
      link = esimDirectoriesEditLinks[directoryType.value];
    } else if (!!id && !data) {
      link = esimDirectoriesDeleteLinks[directoryType.value];
    } else {
      link = esimDirectoriesAddLinks[directoryType.value];
    }
    const res = await esimDirectoryActions(store, data, id, link);
    if (res) fetchData(directoryType.value);
    return res;
  };

  return {
    rows,
    filter,
    currentSort,
    tabs,
    tableHead,
    currentData: esimDirectoriesModel,
    totalDirectories: esimDirectoriesModel.value.length,
    searchQuery,
    sortedModel,
    selectedFilterOption,
    viewConfiguration,
    pageTitle,
    selectedTab,
    directoryType,
    initialSortOptions,
    currentSortKeyLocationName,
    changeTabHandler(data: any) {
      selectedTab.value = <ITab>data;
      directoryType.value = <TEsimDirectoryTypes>(
        esimDirectoriesTypes[
          data.type as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
        ]
      );
    },
    dataTypes: computed(() =>
      store.getters.esimDirectoriesList(esimDirectoriesTypes.dataTypes)
    ),
    esimServersFree: computed(() =>
      store.getters.esimDirectoriesList(esimDirectoriesTypes.esimServersFree)
    ),
    esimServersAll: computed(() =>
      store.getters.esimDirectoriesList(esimDirectoriesTypes.esimServers)
    ),
    uploadImageHandler,
    esimDirectoriesList,
    fetchData,
    onFilterSelect,
    searchHandler,
    changeSorting,
    submitHandler
  };
};
