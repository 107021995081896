import {
  esimDirectoriesGetLinks,
  esimDirectoriesTypes,
  esimDirectoriesTitles
} from "./../types/esimDirectories.types";
export const esimDirectoriesTabs = () => {
  const data = Object.keys(esimDirectoriesTypes);

  return data
    .map((key, idx) => {
      return {
        id: idx,
        title:
          esimDirectoriesTitles[
            key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
          ],
        value:
          esimDirectoriesGetLinks[
            key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
          ],
        type:
          esimDirectoriesTypes[
            key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
          ]
      };
    })
    .splice(0, data.length - 1);
};
